import { Browser } from "@capacitor/browser";
import { UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { CommonDialog } from "@src/appV2/lib";
import { SupportArticleLinks } from "@src/appV2/support/constants";

interface PreviouslyUnassignedAlertProps {
  modalState: UseModalState;
}

export function PreviouslyUnassignedAlert(props: PreviouslyUnassignedAlertProps) {
  const { modalState } = props;
  const ldFlags = useCbhFlags();
  const supportLinks = ldFlags[CbhFeatureFlag?.SUPPORT_LINKS];

  return (
    <CommonDialog
      modalState={modalState}
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            onClick={async () => {
              await Browser.open({
                url:
                  supportLinks?.RATE_NEGOTIATION_WORKER ??
                  SupportArticleLinks.RATE_NEGOTIATION_WORKER,
              });
            }}
          >
            Learn More
          </Button>
          <Button fullWidth variant="outlined" onClick={() => modalState.closeModal()}>
            Okay
          </Button>
        </>
      }
      title="Cannot Propose Rate"
    >
      Some shifts cannot be negotiated because of user actions, such as when a shift has been
      cancelled or unassigned
    </CommonDialog>
  );
}
